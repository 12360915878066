import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component  } from '@angular/core';
import { HTTPService } from '../../http.service';

@Component({
    // moduleId: module.id,
    selector: 'content-component-albums',
    templateUrl: 'ads.component.html',
    styleUrls: [ 'ads.component.css' ],
    providers: [HTTPService]
})

export class AdsComponent {
    constructor( private _httpService: HTTPService, private http:HttpClient) {
        this.getItems();
    }

    ItemsData: any = [];

    resetViewCount(itemId) {
      this._httpService.getData('promotions/reset/' + itemId)
        .subscribe(
          data => this.getItems(),
          error => alert(error),
        );
    }

    delete(id: any) {      
      var x_key = this._httpService.getCookie('x_key');

      let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + x_key,
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
      });
  
      let options = {
          headers: headers,
      };

      this.http.delete(`${this._httpService.apiurl}promotions/` + id, options).subscribe(console.log)
    }

    getItems() {
        this._httpService.getData('promotions').subscribe(
            data => this.ItemsData = data,
            error => alert(error),
            () => this.generategetItems()
        );
    }

    generategetItems() {
        const items = JSON.parse(this.ItemsData._body);
        console.log(items);
        this.ItemsData = items.data;
    }

    activeActions(th) {
        th.target.parentElement.parentElement.classList.toggle('show');
    }
}
