import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NewActionService } from './components/articles/new-actions/service/new-action.service';
import {HTTPService} from './http.service';

@Component({
    // moduleId: module.id,
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']

})



export class AppComponent {
    public router:any;
    public httpService:any;
    articlesAdd = false;

    constructor(private _router: Router, private _httpService: HTTPService, private routerHttp:NewActionService) {
        this.router = _router;
        this.httpService = _httpService;

        let token = this.httpService.getCookie('x_key');
        /*if(token.length > 0 && this.router.url === '/') {
          this.router.navigateByUrl('/dashboard');
        }*/

        if(window.location.href.includes('/articles/')){
            this.articlesAdd = true;
        }

        this.routerHttp.class.subscribe((o) => this.articlesAdd = o);
    }

    isLogin(){
        return false;
    }

    showSideBar(){
        const div = document.getElementById('sidebar');
        const arrowDiv = document.getElementById('arrow')
        arrowDiv.classList.toggle('show-arrow')
        div.classList.toggle('show')
    }

}
