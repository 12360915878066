import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { AppSettings } from "src/app/config/config.module";

@Injectable({
    providedIn: 'root'
})

export class HttpService{

    url = AppSettings.API_URL;

    menuItemEvent:EventEmitter<any> = new EventEmitter();


    constructor(private http:HttpClient){}
    
    getMenuList(id:string){
        let options = this.getHeader() 
        return this.http.get(`${this.url}menu/front`, options);
    }

    addMenuItem(item:any){
        let options = this.getHeader() 

        return this.http.post(`${this.url}menu/admin`, item, options);
    }

    editMenuItem(item:any){
        let options = this.getHeader() 

        return this.http.post(`${this.url}menu/admin/edit`, item, options); 
    }

    editMenuSubItem(item:any){
        let options = this.getHeader() 

        return this.http.put(`${this.url}menu/admin/edit`, item, options); 
    }

    deleteMenuItem(item:any){
        let options = this.getHeader() 

        return this.http.post(`${this.url}menu/admin/delete`, item ,options);
    }

    getHeaderAndParams(id:string, name:string){
        var x_key = this.getCookie('x_key');

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + x_key,
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });

        let queryParams = new HttpParams();
        queryParams = queryParams.append(name, id);
    
        let options = {
            headers: headers,
            params: queryParams,
        };

        return options
    }

    getHeader(){
        var x_key = this.getCookie('x_key');

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + x_key,
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });
    
        let options = {
            headers: headers,
        };

        return options
    }

    getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;
    
        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
    
        return '';
    }

}