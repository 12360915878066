import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Menu } from "./bar-child/menu-bar-child.component";
import { HttpService } from "./service/http.service";

@Component({
    selector: 'menu-bar-component',
    templateUrl: 'menu-bar.component.html',
    styleUrls: [ 'menu-bar.component.css' ],
})

export class MenuBarComponent implements OnInit{

    menuArray = ['Desktop','Mobile','Burger'];
    active = 'Desktop';
    menuAddForm:FormGroup;
    showForm = false;
    mainImgUrlForSendRequest:FormData = new  FormData();
    url:any
    error = false;
    success = false;
    param = '1';

    constructor(private fb:FormBuilder, private router:Router, private http:HttpService){}
    
    ngOnInit(): void {
        this.createMenuAddForm();
    }

    createMenuAddForm(){
        this.menuAddForm = this.fb.group({
            title: new FormControl('',Validators.required),
            url: new FormControl('', Validators.required),
            blank: new FormControl(false),
            new: new FormControl(false),
        })
    }

    saveParam(item){
        this.param = Menu[item];
    }

    changeActive(event:string){
        this.active = event;
    }

    navigate(name:string){
        this.router.navigate(['menu-bar', name])
    }
    
    firstImg(event:any){
        var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);


        this.mainImgUrlForSendRequest.append('file', event.target.files[0], event.target.files[0].name)
		reader.onload = (_event) => {
			this.url = reader.result;
		}

    }

    addNewMenuItem(){
        if(this.menuAddForm.valid){
            const item = {
                name: this.menuAddForm.get('title').value,
                url: this.menuAddForm.get('url').value,
                parent: 0,
                blank: this.menuAddForm.get('blank').value ? 1 : 0,
                new: this.menuAddForm.get('new').value ? 1 : 0,
                icon: this.url,
                location: this.param,
            }
            this.http.addMenuItem(item).subscribe({
                next: () => {
                    this.http.menuItemEvent.emit(item);
                    this.showForm = !this.showForm;
                    this.menuAddForm.reset()
                    this.showSuccess()
                },
                error: () => {
                    this.showError()
                }
            })
        }else{
            this.markAsTouched()
        }
    }

    showFormEvent(){
        this.showForm = !this.showForm;
        this.menuAddForm.reset()
    }

    markAsTouched(){
        this.menuAddForm.get('name').markAsDirty();
        this.menuAddForm.get('url').markAsDirty();
    }


    showSuccess(){
        this.success = true;
        setTimeout(() => {
            this.success = false;
        }, 3000);
    }

    showError(){
        this.error = true;
        setTimeout(() => {
            this.error = false;
        }, 3000);
    }
}