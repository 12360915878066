import { Component , ElementRef } from '@angular/core';
import { HTTPService } from '../../../http.service';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../../config/config.module';
import { Lib } from '../../../libraries/lib.module';
import { Router } from '@angular/router';


@Component({
    // moduleId: module.id,
    selector: 'content-component-actions-quizzes',
    templateUrl: 'actions-quizzes.component.html',
    styleUrls: [ 'actions-quizzes.component.css' ],
    providers: [HTTPService]
})

export class ActionsQuizzesComponent {
  open = true;
  disabled = true;

    id: any;
    storage_url: string;
    private sub: any;
    public progressbarLocations = [
      'above', 'below'
    ];
    public ItemData: any = {
      name: '',
      is_challenge: '',
      has_random_questions: '',
      display_questions_count: '',
      has_random_answers: '',
      has_play_again_btn: '',
      has_start_quiz_btn: '',
      start_quiz_btn_text: '',
      progressbar: '',
      is_visible: '',
      questions: [],
    };

    constructor( private _httpService: HTTPService, private elRef: ElementRef, private route: ActivatedRoute, private router: Router ) {
        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];
         });

        this.storage_url = AppSettings.STORAGE_URL;
        if (this.id != 'add') {
            this.id = parseInt(this.id);
            this.getItem();
        } else {

        }

      document.body.addEventListener('click', function (evt) {
        // if (evt.target.className === 'btn btn-link') {
        //   evt.preventDefault();
        // }
      }, false);
    }

    addQuestion() {
      this.ItemData.questions.push({
        type_id: 1,
        text: '',
        image: null,
        answers: []
      });
    }

    deleteQuestion($event, qIdx) {
      $event.preventDefault();

      this.ItemData.questions.splice(qIdx, 1);
    }

    addText() {
      this.ItemData.questions.push({
        type_id: 2,
        text: '',
        image: null
      });
    }

    addAnswer(questionIdx) {
      this.ItemData.questions[questionIdx].answers.push({
        text: '',
        image: null,
        is_correct: false,
        explanation: ''
      });
    }

    deleteAnswer($event, qIdx, aIdx) {
      $event.preventDefault();

      this.ItemData.questions[qIdx].answers.splice(aIdx, 1);
    }

    saveItem(e) {
        e.preventDefault();
        const data = Lib.getFormData(e);

        let id = '';

        if (this.id != 'add') {
            id = '/' + this.id;
        }

        console.log('quizzez'+id);
        console.log(data);
        // this._httpService.postData( 'quizzez'+id , data).subscribe(
        //     data => this.ItemData = data,
        //     error => alert(error),
        //     () => this.checkAction()
        // );

    }

    checkAction() {
        if (this.id === 'add') {
            // @ts-ignore
          const itm = JSON.parse(this.ItemData._body);
            this.router.navigate(['quizzes/' + itm.id]);
        } else {
            this.generateItem();
        }
    }


    getItem() {
      this._httpService.getData('quizzes/' + this.id).subscribe(
            data => this.ItemData = data,
            error => alert(error),
            () => this.generateItem()
        );

    }

    generateItem() {
        const cat = JSON.parse(this.ItemData._body);
        this.ItemData = cat;
    }

  // tslint:disable-next-line:use-life-cycle-interface
    ngOnDestroy() {
        this.sub.unsubscribe();
    }

  convertToPlain(html) {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }
}
