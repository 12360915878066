import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'app',
    template: 'Not Found component'
    // templateUrl: 'dashboard.component.html',
    // styleUrls: ['dashboard.component.css']
})

export class NotfoundComponent{

}