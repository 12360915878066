import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { title } from "process";
import { HttpService } from "../service/http.service";

export enum Menu{
    Desktop = '1',
    Mobile = '2',
    Burger = '3'
}


@Component({
    selector: 'bar-child-component',
    templateUrl: 'menu-bar-child.component.html',
    styleUrls: [ 'menu-bar-child.component.css' ]
})

export class MenuBarChildComponent implements OnInit{

    routerName:string;
    edit = false;

    form:FormGroup;
    menuSubItemForm:FormGroup;
    showForm = false
    showMenuSubItemForm = false;
    id:number;
    subItemId:number;
    paramsId:string
    success = false;
    error = false
    mainImgUrlForSendRequest:FormData = new  FormData();
    subMenuImgUrlForSendRequest:FormData = new  FormData();
    url:any
    subItemUrl:any;

    itemArray:any;
    subItemArray:any = [];

    constructor(private activateRoute:ActivatedRoute, private fb:FormBuilder, private http:HttpService){}

    ngOnInit(): void {
        this.getParam();
        this.http.menuItemEvent.subscribe(o => this.itemArray.push({...o, status:false}));
    }

    getParam(){
        this.activateRoute.params.subscribe(o =>{
            this.routerName = o['name'];
            this.paramsId = Menu[this.routerName];
            this.http.getMenuList(this.paramsId).subscribe((o:any) => {
                this.itemArray = o.filter(o => o.parent === 0).filter(o => o.location === this.paramsId);
                this.itemArray = this.itemArray.map(o => o = {...o, status:false})
            })
        });
    }

    activeActions(th){
        th.stopPropagation()
        th.target.parentElement.parentElement.classList.toggle('show');
    }

    createForm(o:any, name:string, id:number, th){
        th.stopPropagation()
        th.target.parentElement.parentElement.classList.toggle('show');
        this.id = id;
        if(name !== 'add'){
            this.url = o.icon ? o.icon : '';
            this.edit = true;
            this.form = this.fb.group({
                title: new FormControl(o.name, Validators.required),
                url: new FormControl(o.url, Validators.required),
                blank: new FormControl(o.blank ? true : false),
                new: new FormControl(o.name ? true : false),
            })
        }else{
            this.url = ''
            this.edit = false
            this.form = this.fb.group({
                title: new FormControl('', Validators.required),
                url: new FormControl('', Validators.required),
                blank: new FormControl(false),
                new: new FormControl(false),
            })
        }
        this.showForm = true;
    }

    showSubItem(i){ 
        const item = this.itemArray.filter(o => o.id === i)[0] 
        if(!item.status){
            this.http.getMenuList(this.paramsId).subscribe((o:any) => {
                this.subItemArray = o.filter(o => o.parent === item.id);
                item.status = !item.status   
            })
        }
        this.itemArray.map(o => {
            if(o.status){
                o.status = false;
            }
            return o
        })
    }

    addNewMenuItem(id:number){
        const item = {
            name: this.form.get('title').value,
            url: this.form.get('url').value,
            parent: 0,
            blank: this.form.get('blank').value ? 1 : 0,
            new: this.form.get('new').value ? 1 : 0,
            icon: this.url,
            location: this.paramsId,
        }
        if(this.edit){
            const edit = {id:this.itemArray[id].id, ...item}
            this.http.editMenuItem(edit).subscribe({
                next: () => {
                    this.showForm = !this.showForm; 
                    this.showSuccess()
                    this.getParam();
                },
                error: () => {
                    this.showError()
                }
            })
        }else{
            item.parent = +this.itemArray[id].id;
            this.http.addMenuItem(item).subscribe({
                next: () => {
                    this.url = ''
                    this.showForm = !this.showForm;
                    this.showSuccess()
                    this.getParam();
                },
                error: () => {
                    this.showError()
                }
            })
        }
    }

    editMenuSubItem(o:any, id:number, th){
        th.target.parentElement.parentElement.classList.toggle('show');
        this.subItemUrl = o.icon ? o.icon : '';
        this.subItemId = id;
        this.menuSubItemForm = this.fb.group({
            title: new FormControl(o.name),
            url: new FormControl(o.url),
            blank: new FormControl(o.blank ? true : false),
            new: new FormControl(o.name ? true : false),
        })
        this.showMenuSubItemForm = true;
    }

    addEditMenuSubItem(event:any){
        const item = {
            id: event.id,
            name: this.menuSubItemForm.get('title').value,
            url: this.menuSubItemForm.get('url').value,
            parent: event.parent,
            blank: this.menuSubItemForm.get('blank').value ? 1 : 0,
            new: this.menuSubItemForm.get('new').value ? 1 : 0,
            icon: this.subItemUrl,
            location: '1'
        }
        this.http.editMenuItem(item).subscribe({
            next: () => {
                this.subItemUrl = '';
                this.showForm = !this.showForm;
                this.showSuccess()
                this.getParam();
            },
            error: () => {
                this.showError()
            }
        })
        this.showMenuSubItemForm = false;
    }

    close(subItem:string){
        if(subItem){
            this.showMenuSubItemForm = false;
        }else{
            this.showForm = false;
        }
    }

    deleteMenuItem(i:any, th){
        th.stopPropagation();
        this.http.deleteMenuItem({menuId: i.id}).subscribe({
            next: () => {
                this.showForm = !this.showForm;
                this.showSuccess()
                this.getParam();
            },
            error: () => {
                this.showError()
            }
        })   
    }

    deleteSubItem(i:any, th){
        th.stopPropagation()
        this.http.deleteMenuItem({menuId: i.id}).subscribe({
            next: () => {
                this.showForm = !this.showForm;
                this.showSuccess()
                this.getParam();
            },
            error: () => {
                this.showError()
            }
        })  
    }

    showSuccess(){
        this.success = true;
        setTimeout(() => {
            this.success = false;
        }, 3000);
    }

    showError(){
        this.error = true;
        setTimeout(() => {
            this.error = false;
        }, 3000);
    }

    uploadImg(event:any){
        var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);


        this.mainImgUrlForSendRequest.append('file', event.target.files[0], event.target.files[0].name)
		reader.onload = (_event) => {
			this.url = reader.result;
		}
    }

    uploadSubItemImg(event:any){
        var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);


        this.subMenuImgUrlForSendRequest.append('file', event.target.files[0], event.target.files[0].name)
		reader.onload = (_event) => {
			this.subItemUrl = reader.result;
		}
    }
}