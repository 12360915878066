
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { AppSettings } from "src/app/config/config.module";

@Injectable({
    providedIn: 'root'
})

export class NewActionService{

    url = AppSettings.API_URL;

    class:EventEmitter<boolean> = new EventEmitter()

    constructor(private http:HttpClient){}

    send(item:any){

        let options = this.getHeader() 

        return this.http.post(`${this.url}articles`, item, options);
    }

    getArticle(id:string){
        let options = this.getHeader() 

        return this.http.get(`${this.url}articles/${id}`, options);
    }

    editArticle(id:string, item:any){
        let options = this.getHeader() 

        return this.http.patch(`${this.url}articles/${id}`, item, options);
    }

    searchTeams(name:string){
        let options = this.getHeader() 

       return this.http.get(`${this.url}teams?search=${name}`, options)
    }
    
    searchPlayers(name:string){
        let options = this.getHeader() 

       return this.http.get(`${this.url}players?search=${name}`, options)
    }

    searchLeagues(name:string){
        let options = this.getHeader() 

       return this.http.get(`${this.url}leagues?search=${name}`, options)
    }

    searchNews(name:string){
        let options = this.getHeader() 

       return this.http.get(`${this.url}articles/search?q=${name}`, options)
    }

    getCategoriesList(){
        let options = this.getHeader() 

       return this.http.get(`${this.url}categories`, options)
    }

    sendSurvey(item:any){
        let options = this.getHeader() 

        return this.http.post(`${this.url}polls`, item, options);
    }

    uploadImg(item){
        let options = this.getHeader() 

        return this.http.post(`${this.url}gallery/upload`,item, options)
    }

    uploadVideo(item){
        let options = this.getHeader();
        return this.http.post(`${this.url}videos/upload`, item, options)
    }

    getHeader(){
        var x_key = this.getCookie('x_key');

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + x_key,
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });
    
        let options = {
            headers: headers,
        };

        return options
    }

    getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;
    
        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
    
        return '';
    }

}